import { defineStore } from 'pinia'

export const useConfigsStore = defineStore('configs', {
  state: () => {
    return {
      common: {
        lang: 'de'
      }
    }
  },
  getters: {
    getLang(state) {
      return state.common.lang;
    }
  },
  actions: {
    setCommon(configs) {
      Object.assign(this.common, configs)
      window.localStorage.setItem('config:common', JSON.stringify(this.common))
    },
  },
})