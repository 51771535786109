export const messages = {
    en: {
        common: {
            back: 'Back',
            open: 'Open',
            delete: 'Delete',
            edit: 'Edit',
            cancel: 'Cancel',
            next: 'Next',
            selectAll: 'Select all',
            share: 'Share territory',
            copy: 'Copy',
            fileName: 'File name',
            backTo: 'Back to "{title}"',
            address: 'Address',
        },
        lang: {
            en: 'English',
            es: 'Spanish',
            de: 'German',
        },
        home: {
            title: 'My territories',
            importedAt: 'Imported: {date}',
            confirmDeleteTerritory: 'Do you really want to remove this territory from your list?',
            dropFile: "+ Select or drop json file here",
            addMap: 'Add territory',
            scanMap: 'Scan QR code',
            importFile: 'Import file',
            invalidFile: 'Invalid file!'
        },
        config: {
            title: 'Configuration',
            theme: 'Theme',
            bright: 'Bright',
            dark: 'Dark',
            lang: 'Language'
        },
        admin: {
            title: 'Manage territories',
            import: 'Import',
            export: 'Export',
            save: 'Save',
            noMaps: 'No territories found',
            territoryName: 'Name of the territory',
            createTerritory: 'Create territory',
            print: 'Print',
            collection: 'Collection',
            allCollections: 'All',
            newCollection: 'Create a collection',
            editCollection: 'Edit this collection',
            deleteCollection: 'Delete this collection',
            nameOfCollection: 'Name of the collection',
            confirmDeleteCollection: 'Do you really want to delete the collection "{collection}"?',
            confirmDeleteTerritory: 'Do you really want to delete this territory? | Do you really want to delete these {count} territories?',
            moveToCollection: 'Move to another collection',
            territoriesSelected: 'One territory selected | {count} territories selected',
            multipleSelect: 'Select multiple',
            editingTerritories: 'Editing the selected territory | Editing the {count} selected territories',
            editingCollection: 'Edit "{collection}"',
            dropFile: "+ Select or drop kml, kmz or json file here",
            territoryOverview: 'List of territories',
            searchTerritories: 'Search territories'
        },
        map: {
            title: 'Map view',
            highlight: 'Highlight',
            stopHighlight: 'Done',
            delete: 'Delete',
            clear: 'Clear',
            confirmDeleteDrawings: 'Do you really want to delete all drawings?',
            shareLink: 'Just the territory',
            shareFile: 'Territory with marks',
            navigateToAddress: 'Navigate to address',
            shareAddress: 'Share address'
        },
        print: {
            title: 'Print',
            selectTerritories: 'Select territories to print',
            defaultNotesTitle: 'DO NOT VISIT *',
            defaultNotesFooter: '* Add only address if person explicitly asked to do so'
        },
        scan: {
            title: 'Scan QR of territory'
        }
    },
    es: {
        common: {
            back: 'Volver',
            open: 'Abrir',
            delete: 'Borrar',
            edit: 'Editar',
            cancel: 'Cancelar',
            next: 'Siguiente',
            selectAll: 'Seleccionar todos',
            share: 'Compartir territorio',
            copy: 'Copiar',
            fileName: 'Nombre del archivo',
            backTo: 'Volver a "{title}"',
            address: 'Dirección',
        },
        lang: {
            en: 'Inglés',
            es: 'Español',
            de: 'Alemán',
        },
        home: {
            title: 'Mis territorios',
            importedAt: 'Importado: {date}',
            confirmDeleteTerritory: '¿Realmente deseas quitar este territorio de tu lista?',
            dropFile: "+ Selecciona o arrastra aquí un archivo json",
            scanMap: 'Escanear QR',
            importFile: 'Importar archivo',
            addMap: 'Añadir territorio',
            invalidFile: '¡Archivo inválido!'
        },
        config: {
            title: 'Ajustes',
            theme: 'Apariencia',
            bright: 'Claro',
            dark: 'Oscuro',
            lang: 'Idioma'
        },
        admin: {
            title: 'Editar territorios',
            import: 'Importar',
            export: 'Exportar',
            save: 'Guardar',
            noMaps: 'No se han encontrado territorios',
            territoryName: 'Nombre del territorio',
            createTerritory: 'Crear territorio',
            print: 'Imprimir',
            collection: 'Colección',
            allCollections: 'Todos',
            newCollection: 'Crear colección',
            editCollection: 'Editar esta colección',
            deleteCollection: 'Borrar esta colección',
            nameOfCollection: 'Nombre de la colección',
            confirmDeleteCollection: '¿Realmente deseas borrar la colección "{collection}"?',
            confirmDeleteTerritory: '¿Realmente deseas borrar este territorio? | ¿Realmente deseas borrar estos {count} territorios?',
            moveToCollection: 'Mover a otra colección',
            territoriesSelected: 'Un territorio seleccionado | {count} territorios seleccionados',
            multipleSelect: 'Seleccionar varios',
            editingTerritories: 'Editar el territorio seleccionado | Editar los {count} territorios seleccionados',
            editingCollection: 'Editar "{collection}"',
            dropFile: "+ Selecciona o arrastra aquí un archivo kml, kmz o json",
            territoryOverview: 'Lista de territorios',
            searchTerritories: 'Buscar territorios'
        },
        map: {
            title: 'Mapa del territorio',
            highlight: 'Marcar',
            stopHighlight: 'Hecho',
            delete: 'Borrar',
            clear: 'Borrar todo',
            confirmDeleteDrawings: '¿Seguro que deseas borrar todas las marcas?',
            shareLink: 'Solo el territorio',
            shareFile: 'Territorio con marcas',
            navigateToAddress: 'Navegar aquí',
        },
        print: {
            title: 'Imprimir territorios',
            selectTerritories: 'Selecciona los territorios a imprimir',
            defaultNotesTitle: 'NO VISITAR *',
            defaultNotesFooter: '* Anotar solamente dirección si la persona lo ha pedido expresamente'
        },
        scan: {
            title: 'Escanear código QR del territorio'
        }
    },
    de: {
        common: {
            back: 'Zurück',
            open: 'Öffnen',
            delete: 'Löschen',
            edit: 'Bearbeiten',
            cancel: 'Abbrechen',
            next: 'Weiter',
            selectAll: 'Alle auswählen',
            share: 'Gebiet teilen',
            copy: 'Kopieren',
            fileName: 'Dateiname',
            backTo: 'Zurück zu "{title}"',
            address: 'Adresse',
        },
        lang: {
            en: 'Englisch',
            es: 'Spanisch',
            de: 'Deutsch',
        },
        home: {
            title: 'Meine Gebiete',
            importedAt: 'Importiert am {date}',
            confirmDeleteTerritory: 'Möchtest du wirklich dieses Gebiet aus deiner Liste entfernen?',
            dropFile: "+ json Datei hier auswählen oder ablegen",
            scanMap: 'QR Code scannen',
            importFile: 'Datei importieren',
            addMap: 'Gebiet hinzufügen',
            invalidFile: 'Ungültige Datei!'
        },
        config: {
            title: 'Einstellungen',
            theme: 'Aussehen',
            bright: 'Hell',
            dark: 'Dunkel',
            lang: 'Sprache'
        },
        admin: {
            title: 'Gebiete bearbeiten',
            import: 'Importieren',
            export: 'Exportieren',
            save: 'Speichern',
            noMaps: 'Keine Gebiete gefunden',
            territoryName: 'Name des Gebiets',
            createTerritory: 'Gebiet erstellen',
            print: 'Drucken',
            collection: 'Sammlung',
            allCollections: 'Alle',
            newCollection: 'Sammlung erstellen',
            editCollection: 'Sammlung bearbeiten',
            deleteCollection: 'Sammlung löschen',
            nameOfCollection: 'Name der Sammlung',
            confirmDeleteCollection: 'Möchtest du wirklich die Sammlung "{collection}" löschen?',
            confirmDeleteTerritory: 'Möchtest du wirklich dieses Gebiet löschen? | Möchtest du wirklich diese {count} Gebiete löschen?',
            moveToCollection: 'In eine andere Sammlung verschieben',
            territoriesSelected: 'Ein Gebiet gewählt | {count} Gebiete gewählt',
            multipleSelect: 'Mehrfachauswahl',
            editingTerritories: 'Das gewählte Gebiet bearbeiten | Die {count} gewählten Gebiete bearbeiten',
            editingCollection: '"{collection}" bearbeiten',
            dropFile: "+ kml, kmz oder json Datei hier auswählen oder ablegen",
            territoryOverview: 'Gebietsübersicht',
            searchTerritories: 'Gebiete suchen'
        },
        map: {
            title: 'Gebietansicht',
            highlight: 'Markieren',
            stopHighlight: 'Fertig',
            delete: 'Löschen',
            clear: 'Alle Markierungen löschen',
            confirmDeleteDrawings: 'Wirklich alle Markierungen löschen?',
            shareLink: 'Nur das Gebiet',
            shareFile: 'Gebiet mit Markierungen',
            navigateToAddress: 'Hierhin navigieren',
        },
        print: {
            title: 'Gebiete drucken',
            selectTerritories: 'Gebietauswahl zum Ausdrucken',
            defaultNotesTitle: 'NICHT BESUCHEN *',
            defaultNotesFooter: '* Nur Adresse notieren wenn Person ausdrücklich darum gebeten hat'
        },
        scan: {
            title: 'QR Code des Gebiets scannen'
        }
    }
}