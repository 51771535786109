import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'

import App from './App.vue'

import OpenLayersMap from 'vue3-openlayers'
import 'vue3-openlayers/dist/vue3-openlayers.css'
import router from './router'
import './registerServiceWorker'

import { messages } from '@/i18n/global'

const i18n = createI18n({
    locale: navigator.language, // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
    sync: true
})

const app = createApp(App)

app.use(router)
app.use(i18n)
app.use(OpenLayersMap)
app.use(createPinia())
app.mount('#app')