import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/pg/:encodedPolyline',
    name: 'polygon',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MapView.vue'),
    props: route => ({ encodedPolyline: route.params.encodedPolyline, title: route.query.n }),
    meta: {
      title: 'map.title',
      transition: 'zoom',
      position: 'center',
      bookmark: true
    }
  },
  {
    path: '/print',
    name: 'print',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PrintView.vue'),
    meta: {
      title: 'print.title',
      position: 'center'
    }
  },
  {
    path: '/',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
    props: true,
    meta: {
      title: 'home.title',
      transition: 'slideleft-slideright',
      position: 'left'
    }
  },
  {
    path: '/admin',
    name: 'admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminView.vue'),
    props: true,
    meta: {
      title: 'admin.title',
      position: 'center',
      bookmark: true
    }
  },
  {
    path: '/encode',
    name: 'encode',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EncodeView.vue'),
    props: true,
    meta: {
      title: 'encode.title'
    }
  },
  {
    path: '/config',
    name: 'config',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ConfigView.vue'),
    props: true,
    meta: {
      title: 'config.title',
      transition: 'slideright-slideleft',
      position: 'right'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to, from) => {
  if (from.meta.transition == 'zoom') {
    if (to.meta.transition == 'slideright-slideleft') {
      to.meta.transition = 'slideright-zoom';
    } else {
      to.meta.transition = 'slideleft-zoom';
    }
  } else if (to.meta.transition == 'zoom') {
    if (from.meta.transition == 'slideright-slideleft') {
      to.meta.transition = 'zoom-slideright';
    }
    if (from.meta.transition == 'slideleft-slideright') {
      to.meta.transition = 'zoom-slideleft';
    }
  }
})

export default router